<template>
  <section class="container pb-8">
    <div class="row">
      <div class="col-12">
        <router-link
          to="/novices/policy"
          class="btn btn-hover-secondary w-100 text-left py-5 px-8 mb-2"
        >
          服務條款
        </router-link>
        <router-link
          to="/novices/buyNote"
          class="btn btn-hover-secondary w-100 text-left py-5 px-8 mb-2"
        >
          購買須知
        </router-link>
        <router-link
          to="/novices/about"
          class="btn btn-hover-secondary w-100 text-left py-5 px-8 mb-2"
        >
          關於我們
        </router-link>
        <router-link
          to="/novices/class"
          class="btn btn-hover-secondary w-100 text-left py-5 px-8 mb-2"
        >
          班別介紹
        </router-link>
        <router-link
          to="/novices/buyProcess"
          class="btn btn-hover-secondary w-100 text-left py-5 px-8 mb-2"
        >
          購買流程
        </router-link>
        <router-link
          to="/novices/question"
          class="btn btn-hover-secondary w-100 text-left py-5 px-8 mb-2"
        >
          常見問題
        </router-link>
        <router-link
          to="/novices/contact"
          class="btn btn-hover-secondary w-100 text-left py-5 px-8 mb-2"
        >
          聯絡我們
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
